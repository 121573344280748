#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 3.5rem; /* Footer height */
}

a {
  text-decoration: none;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3.5rem; /* Footer height */
}

#catalogue > div > .card{
  transition: filter 0.05s ease;
}

#catalogue > div > .card:hover {
  filter: brightness(90%); 
}